<template>
    <el-container>
        <Header></Header>
        <el-main>
            <router-view/>
        </el-main>
    </el-container>

</template>
<script>
    import Header from "c/qrCode/Header";

    export default {
        name: "qrCode-index",
        components: {
            Header,
        },
        data() {
            return {};
        },
        methods: {},
        created() {

        },
        destroyed() {

        }
    };
</script>
<style scoped lang="scss">
    .el-container {
        width: 100%;
        height: 100%;
        min-width: 1024px;
        display: flex;
        flex-flow: column;
        overflow: hidden;
        box-sizing: border-box;
    }
</style>
